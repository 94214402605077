import classNames from 'classnames'
import React, { useState } from 'react'
import { Can } from '../../helpers/Can'

function MerchantRecord({
    merchant,
    showUpdateMerchant,
    showUserWhitelist,
    showCreateUPI,
    showUpdateUPI,
    showCreateNEFT,
    showUpdateNEFT,
    showCreateStatementOption,
    showUpdateStatementOption
}) {
    return (
        <tr >
            <td className="border-bottom-0">
                <p className="d-flex align-items-center mb-0 fw-normal">
                    {merchant?.default && (
                        <i className="ti ti-check me-1 fw-semibold fs-5 text-success"></i>
                    )}
                    <span>{merchant.name}</span>
                </p>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{merchant?.owner?.name}</p>
            </td>
            <td className="border-bottom-0">
                <p className="mb-0 fw-normal">{merchant._id}</p>
                <p className="mb-0 mt-1 fw-normal">{merchant.callBackUrl}</p>
            </td>
            <Can I="read" a="Upi">
                <td className="border-bottom-0">
                    <div className="d-flex align-items-center gap-2">
                        {merchant.upi && (
                            <button className="d-flex align-items-center btn btn-sm btn-outline-secondary rounded-3 fw-semibold"
                                onClick={() => showUpdateUPI(merchant._id, merchant.upi)}>
                                {merchant?.upi?.enabled && (
                                    <i className="ti ti-point-filled me-1 fw-semibold fs-4 text-success"></i>
                                ) || (
                                        <i className="ti ti-point-filled me-1 fw-semibold fs-4 text-danger"></i>
                                    )}
                                <span>{merchant?.upi?.vpa}</span>
                            </button>
                        ) || (
                                <Can I="create" a="Upi">
                                    <button onClick={() => showCreateUPI(merchant._id)} className='btn btn-sm btn-outline-primary rounded-3 fw-semibold'>
                                        <span>
                                            <i className="ti ti-plus me-1"></i>
                                            Add
                                        </span>
                                    </button>
                                </Can>
                            )}
                    </div>
                </td>
            </Can>
            <Can I="read" a="Neft">
                <td className="border-bottom-0">
                    <div className="d-flex align-items-center gap-2">
                        {merchant.neft && (
                            <button className="d-flex align-items-center btn btn-sm btn-outline-secondary rounded-3 fw-semibold"
                                onClick={() => showUpdateNEFT(merchant._id, merchant.neft)}>
                                {merchant?.neft?.enabled && (
                                    <i className="ti ti-point-filled me-1 fw-semibold fs-4 text-success"></i>
                                ) || (
                                        <i className="ti ti-point-filled me-1 fw-semibold fs-4 text-danger"></i>
                                    )}
                                <span>{merchant?.neft?.acNumber}</span>
                            </button>
                        ) || (
                                <Can I="create" a="Neft">
                                    <button onClick={() => showCreateNEFT(merchant._id)} className='btn btn-sm btn-outline-primary rounded-3 fw-semibold'>
                                        <span>
                                            <i className="ti ti-plus me-1"></i>
                                            Add
                                        </span>
                                    </button>
                                </Can>
                            )}
                    </div>
                </td>
            </Can>
            <Can I="read" a="StatementType">
                <td className="border-bottom-0">
                    <div className="d-flex align-items-center gap-2 st-options-col">
                        {merchant.statementTypes && !!merchant.statementTypes.length && (
                            merchant.statementTypes.map((type, key) => (
                                <button className="d-flex align-items-center btn btn-sm btn-outline-secondary rounded-3 fw-semibold" key={key}
                                    onClick={() => showUpdateStatementOption(merchant._id, type)}>
                                    {type?.default && (
                                        <i className="ti ti-check me-1 fw-semibold fs-5 text-success"></i>
                                    )}
                                    <span>{type.name}</span>
                                </button>
                            ))
                        )}
                        <Can I="create" a="StatementType">
                            <button onClick={() => showCreateStatementOption(merchant._id)}
                                className='btn btn-sm btn-outline-primary rounded-3 fw-semibold'>
                                <i className="ti ti-plus me-1"></i>
                                <span>Add</span>
                            </button>
                        </Can>
                    </div>
                </td>
            </Can>
            <td className="border-bottom-0">
                <div className="d-flex align-items-center gap-2">
                    <Can I="update" this={merchant} a="Merchant">
                        <button className="btn btn-sm btn-outline-info rounded-3 fw-semibold"
                            onClick={() => showUpdateMerchant(merchant._id, merchant.name, merchant.min, merchant.max, merchant.callBackUrl, merchant.default, merchant?.owner?._id)}>
                            <i className="ti ti-edit me-1"></i>
                            <span>Update</span>
                        </button>
                    </Can>
                    <Can I="userWhitelist" this={merchant} a="Merchant">
                        <button className="btn btn-sm btn-outline-info rounded-3 fw-semibold"
                            onClick={() => showUserWhitelist(merchant._id, merchant.whitelistUsers, merchant?.owner?._id)}>
                            <i className="ti ti-lock-check me-1"></i>
                            
                            <span>Whitelist Users ({merchant.whitelistUsers.length})</span>
                        </button>
                    </Can>
                </div>
            </td>
        </tr >)
}

export default MerchantRecord